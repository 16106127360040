import React from "react"
import { navigate, Link } from "gatsby"
import { AppProvider,  Button, Text, Frame, Page, Layout, Card, Loading } from "@shopify/polaris"
import {NavMenu} from '@shopify/app-bridge-react';
import en from '@shopify/polaris/locales/en.json';
import GraphqlProvider from "../providers/graphql"
import { getFirebase } from "../helpers/firebase"
import FirebaseContext from "../providers/firebase"
import { TourProvider } from '@reactour/tour'
import { refreshAuth } from "../helpers/auth"
import { Crisp } from "crisp-sdk-web";
import { v4 as uuidv4 } from 'uuid'; 
import '@shopify/polaris/build/esm/styles.css';
import { 
    getShopDomain,
    signin,
} from "../helpers/auth"
//import Header from "../components/header"

class AppLayout extends React.Component {
    constructor(props) {
        super(props)

        let shop = null

        if (typeof window !== 'undefined') {
            shop = getShopDomain()

            if (!shop || shop === null || typeof shop == "undefined") {
                navigate(
                    `/install/`,
                    {
                        replace: true,
                    }
                )
            }
        }

        this.state = {
            shop,
            token: null,
            isLoading: true,
        }
    }

    componentDidMount = async () => {
            const queryParams = window.location.search
            const urlParams = new URLSearchParams(queryParams)
            const id_token = urlParams.get("id_token")
            const shop = urlParams.get("shop")
            let token = urlParams.get("token")
            let uid = urlParams.get("uid")
            const host = urlParams.get("host")
            let user_id = urlParams.get("user_id")
            if (!id_token && !uid && shop && host) {
                await refreshAuth(shop, host)
            }else if(id_token && !uid){
                const customToken = await signin(shop, host, id_token)
                uid = customToken?.uid
                token = customToken?.token
                user_id = customToken?.user_id
            } 
            if (uid) {
                const firebaseInstance = await getFirebase(uid)
                if(firebaseInstance){
                    this.setState({
                        shop,
                        firebase: firebaseInstance,
                        token,
                        host,
                        user_id,
                        isLoading: false,
                    })
                }
            }
            if (shop) {
                Crisp.configure("d706d890-199d-4990-b295-9d23ebbaf88a", {
                    autoload: false
                });
                Crisp.setTokenId(user_id || uuidv4());
                Crisp.session.setData({
                    company: shop
                });
                Crisp.load();
            }
    }
    
    render() {
        const { shop, token, host, user_id, isLoading, firebase } = this.state
        const contentComponent = (props) =>{
            const isFirstStep = props.currentStep === 0
            const isLastStep = props.currentStep === props.steps.length - 1
            const content = props.steps[props.currentStep].content
            return (
              <div style={{ minWidth: '260px', background: 'white' }}>
                <Text as="h2" variant="headingMd">Step {props.currentStep+1}:</Text>
                <Text as="p">{content}</Text>
                <div style={{paddingTop:'10px', display:'flex'}}>
                    {!isFirstStep ? 
                        <div style={{width:'120px'}}>
                            <Button
                            fullWidth
                            onClick={() => {
                                props.setCurrentStep((s) => s - 1)
                            }}
                            >
                                Back
                            </Button>
                        </div>
                    : null
                    }
                    <div style={{marginLeft: 'auto', width:'120px'}}>
                        <Button
                        fullWidth
                        onClick={() => {
                            if (isLastStep) {
                            props.setIsOpen(false)
                            } else {
                            props.setCurrentStep((s) => s + 1)
                            }
                        }}
                        >
                        {isLastStep ? 'Close' : 'Next'}
                        </Button>
                    </div>
                </div>
              </div>
            )
          }
        if (isLoading || !shop || !token || !host) {
            return (
                <AppProvider i18n={en}>
                    <Frame>
                        <Loading />
                        <Page fullWidth title="Initializing...">
                            <Layout>
                                <Layout.Section>
                                <Card>
                                    <p>Initializing app...</p>
                                </Card>
                                </Layout.Section>
                            </Layout>
                        </Page>
                    </Frame>
                </AppProvider>
            );
        }
        
        return (
            <AppProvider
                i18n={en}
                apiKey={process.env.GATSBY_SHOPIFY_APP_API_KEY}
            >
                <FirebaseContext.Provider value={{firebase, shop, token, host, user_id}}>
                    <GraphqlProvider
                        shop={shop}
                        token={token}
                    >
                        <TourProvider 
                        steps={[]}
                        ContentComponent={contentComponent}
                        styles={{
                            popover: (base) => ({
                              ...base,
                              '--reactour-accent': '#038060',
                              borderRadius: '0.8rem',
                              padding:'1rem',
                            }),
                            maskArea: (base) => ({ ...base, rx: '0.8rem' }),
                            maskWrapper: (base) => ({ ...base }),
                            badge: (base) => ({ ...base }),
                            controls: (base) => ({ ...base }),
                            close: (base) => ({ ...base }),
                          }}
                        scrollSmooth>
                        <NavMenu>
                            <Link to="/app/" rel="home">
                                Home
                            </Link>
                            <Link to="/app/offers">Offers</Link>
                            <Link to="/app/customizations">Customizations</Link>
                            <Link to="/app/settings">Settings</Link>
                            <Link to="/app/helpdesk">Helpdesk</Link>
                        </NavMenu>
                        {this.props.children}
                        </TourProvider>
                    </GraphqlProvider>
                </FirebaseContext.Provider>
            </AppProvider> 
        )
    }
}


export default AppLayout